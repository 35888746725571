<template>
  <router-link
    :to="{
      name: 'Shop',
      params: { id: shopObjects.shop_id, openPage: '1', scrollPage: '0' },
    }"
    style="text-decoration: none; color: inherit"
  >
    <v-card class="pa-0 ma-4" elevation="4" :ripple="false">
      <v-row no-gutters v-if="shopObjects.shop_img_list.length > 1">
        <v-col cols="6">
          <v-img
            :lazy-src="local_shop"
            :src="shopObjects.shop_img_small"
            max-width="100%"
            :height="200"
            cover
            :alt="shopObjects.shop_title"
          >
            <v-col
              class="ma-2 text-left"
              v-if="
                shopObjects.shop_img_list !== undefined &&
                shopObjects.shop_img_list.length > 1
              "
            >
              <v-btn
                elevation="2"
                color="black"
                rounded="lg"
                :ripple="false"
                prepend-icon="mdi-image"
                @click.prevent="imageOnClick()"
              >
                + {{ shopObjects.shop_img_list.length }}
              </v-btn>
            </v-col>
          </v-img>
        </v-col>

        <v-col cols="6">
          <v-img
            :lazy-src="local_shop"
            :src="shopObjects.shop_img_list[1].picture_url_small"
            max-width="100%"
            :height="200"
            cover
            :alt="shopObjects.shop_title"
          />
        </v-col>
      </v-row>

      <v-row no-gutters v-else>
        <v-col cols="12">
          <v-img
            :lazy-src="local_shop"
            :src="shopObjects.shop_img_small"
            max-width="100%"
            :height="200"
            cover
            :alt="shopObjects.shop_title"
          >
          </v-img>
        </v-col>
      </v-row>

      <div
        class="ribbon ribbon-top-right"
        v-if="
          shopObjects.shop_crowdfundings !== undefined &&
          shopObjects.shop_crowdfundings.length > 0
        "
      >
        <span class="text-body-1">{{ $t("homeview.voucher") }}</span>
      </div>

      <v-row class="mt-0">
        <v-col cols="7">
          <div class="mx-2">
            <v-icon icon="mdi-briefcase" color="black" size="16" class="mx-0" />
            <span style="font-size: 12px" class="mx-2 text-black">
              {{ shopObjects.shop_opening_times }} ~
              {{ shopObjects.shop_closing_times }}
            </span>
          </div>
        </v-col>

        <v-col cols="5">
          <div class="mx-2">
            <v-icon icon="mdi-cash" color="black" size="16" class="mx-0" />
            <span style="font-size: 12px" class="mx-2 text-black">
              {{ shopObjects.shop_price }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="7">
          <div class="mx-2">
            <v-icon icon="mdi-google" color="black" size="16" class="mx-0" />
            <span style="font-size: 12px" class="mx-2 text-black">
              <v-icon icon="mdi-star" color="black" size="12" class="mx-0" />
              {{ shopObjects.os_rating }} ({{ shopObjects.os_reviews }})
            </span>
          </div>
        </v-col>

        <v-col cols="5">
          <div class="mx-2">
            <v-icon icon="mdi-heart" color="black" size="16" class="mx-0" />
            <span style="font-size: 12px" class="mx-2 text-black">
              {{ shopObjects.shop_favorite_count }}
            </span>
          </div>
        </v-col>
      </v-row>

      <v-divider :thickness="0.5" class="ma-2"></v-divider>

      <!--Debug use -->
      <!-- <p>{{ shopObjects.shop_id }}</p> -->

      <v-card-title>
        {{ shopObjects.shop_title }}
      </v-card-title>

      <v-card-subtitle class="mt-n3">
        <v-icon icon="mdi-map-marker" size="14" color="black" />
        <span
          class="text-black mx-2"
          style="font-size: 13px; font-weight: normal"
        >
          {{ getShopPrefectureTitle(shopObjects) }}
        </span>
      </v-card-subtitle>

      <v-list-item class="mt-2">
        <template v-slot:prepend>
          <v-img :src="bot_logo_src" :width="20" alt="" />
        </template>
        <p class="text-body-2 mx-2 mt-1" style="font-weight: bold">
          {{ $t("shop.about_this_shop") }}
        </p>
      </v-list-item>

      <div v-if="!checkEmpty(shopObjects.ai_review)">
        <v-card-text
          class="mt-n5 text-black"
          style="white-space: pre-line"
          v-if="this.$i18n.locale == 'zhCN'"
        >
          <div v-snip="{ lines: 5 }" style="font-weight: normal">
            {{ shopObjects.ai_review_short_zhcn }}
          </div>
        </v-card-text>

        <v-card-text
          class="mt-n5 text-black"
          style="white-space: pre-line"
          v-else-if="this.$i18n.locale == 'ms'"
        >
          <div v-snip="{ lines: 5 }" style="font-weight: normal">
            {{ shopObjects.ai_review_short_ms }}
          </div>
        </v-card-text>

        <v-card-text
          class="mt-n5 text-black"
          style="white-space: pre-line"
          v-else
        >
          <div v-snip="{ lines: 5 }" style="font-weight: normal">
            {{ shopObjects.ai_review_short }}
          </div>
        </v-card-text>
      </div>

      <div v-else>
        <v-card-text class="mt-n5 text-black" style="white-space: pre-line">
          {{ $t("shop.noShopDetails") }}
        </v-card-text>
      </div>

      <v-expansion-panels
        class="mt-2"
        v-if="
          shopObjects.shop_crowdfundings !== undefined &&
          shopObjects.shop_crowdfundings.length > 0
        "
      >
        <v-expansion-panel
          elevation="0"
          @click.prevent="onExpansionPanelClick()"
        >
          <v-expansion-panel-title class="text-subtitle-2">
            <p class="text-subtitle-2 text-green" style="font-weight: bold">
              <v-icon icon="mdi-sale" color="green" size="24px" class="mb-1" />
              {{ $t("homeview.special_voucher") }}
            </p>
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <v-card class="mb-0" elevation="0">
              <v-card-title>
                {{ shopObjects.shop_crowdfundings[0].title }}
              </v-card-title>
              <v-card-text>
                <p v-snip="{ lines: 3 }">
                  {{ shopObjects.shop_crowdfundings[0].description }}
                </p>
              </v-card-text>
              <div class="pa-0 text-end">
                <v-btn
                  class="text-subtitle-2 text-green"
                  elevation="0"
                  variant="outlined"
                  :ripple="false"
                  @click="
                    voucherDetailsOnClick(shopObjects.shop_crowdfundings[0].id)
                  "
                >
                  {{ $t("homeview.show_more") }}
                </v-btn>
              </div>
            </v-card>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </router-link>
</template>


<script>
import Uitls from "@/uitls/Uitls.js";

export default {
  // https://qiita.com/catkk/items/bd474d75b42aae2b92e9
  props: {
    shopObjects: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    // setup() receives props as the first argument.
    // console.log(props.shopObjects);
    // console.log(props.shopObjects.shop_crowdfundings[0]);
  },

  data() {
    return {
      local_shop: require("@/assets/img/logo120.png"),
      bot_logo_src: require("@/assets/img/robot_120.png"),
    };
  },

  mounted() {
    // console.log("mount shop_img: " + this.shopObjects.shop_img);
  },

  methods: {
    checkEmpty(obj) {
      return Uitls.isEmpty(obj) ? true : false;
    },

    imageOnClick() {
      this.$emit("showThumbImage", this.shopObjects.shop_id);
    },

    onExpansionPanelClick() {
      // console.log("marketing_url: " + shopObjects.shop_crowdfundings[0].title);
    },
    voucherDetailsOnClick(itemId) {
      this.$router.push({
        name: "CrowdfundingsDetail",
        params: { crowdfundingId: itemId },
      });
    },

    getShopPrefectureTitle() {
      return this.$i18n.t(Uitls.getShopPrefectureTitle(this.shopObjects));
    },
  },
};
</script>

<style scoped>
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid red;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  background-color: red;
  color: #fff;
  font: 500 16px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.8);
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 22px;
}

.ribbon-top-right::after {
  bottom: 22px;
  right: 0;
}

.ribbon-top-right span {
  left: -27px;
  top: 29px;
  transform: rotate(45deg);
}
</style>