<template>
  <v-sheet
    class="pa-0 ma-0 mx-auto"
    color="white"
    max-width="1200"
    rounded="lg"
    width="100%"
    elevation="0"
  >
    <p class="text-h5 mx-2 mt-4" style="font-weight: bold">
      <v-icon icon="mdi-magnify" color="blue" size="24px" class="mb-1"></v-icon>
      {{ $t("searchresults.search_results") }}
      <span class="text-h5 text-black" style="font-weight: bold">
        {{ totalResult }}
      </span>
    </p>

    <v-sheet color="" class="ma-6">
      <v-row>
        <v-chip-group>
          <v-chip
            v-for="(item, index) in searchPrefectureChip"
            :key="index"
            :closable="item.id != 0"
            @click="closeFilterChips('prefecture_v2')"
            @click:close="closeFilterChips('prefecture_v2')"
          >
            {{ item.code ? $t("prefecture." + item.code) : item.name }}
          </v-chip>
          <v-chip
            v-if="sort == '-id'"
            closable
            @click="closeSortChip()"
            @click:close="closeSortChip()"
          >
            {{ $t("searchfilter.latest") }}
          </v-chip>
          <v-chip
            closable
            @click="closeFilterChips('searchKeyword')"
            v-for="string in searchChip"
            :key="string"
            @click:close="closeFilterChips('searchKeyword')"
          >
            {{ string }}
          </v-chip>
          <v-chip
            closable
            v-for="(string, index) in tagChip"
            :key="index"
            @click="closeFilterChips('selectedTag', index)"
            @click:close="closeFilterChips('selectedTag', index)"
          >
            {{ string }}
          </v-chip>

          <v-chip
            closable
            v-for="string in timeChip"
            :key="string"
            @click="closeFilterChips('selectedTime')"
            @click:close="closeFilterChips('selectedTime')"
          >
            {{ string }}
          </v-chip>

          <v-chip
            v-for="(string, index) in priceChip"
            :key="index"
            closable
            @click="closeFilterChips('priceRange')"
            @click:close="closeFilterChips('priceRange')"
          >
            {{ string }}
          </v-chip>
        </v-chip-group>
      </v-row>

      <v-row justify="end" class="mx-4 mt-3">
        <v-btn
          :ripple="false"
          color="black"
          variant="text"
          prepend-icon="mdi-tune-variant"
          value="left"
          @click="openSearchFilterDialog = true"
        >
          {{ $t("searchresults.filter") }}
        </v-btn>

        <v-btn
          :ripple="false"
          value="right"
          variant="text"
          color="black"
          prepend-icon="mdi-sort"
        >
          {{ $t("searchresults.sort") }}
          <v-menu activator="parent">
            <v-list>
              <v-list-item
                v-for="(item, index) in sortItems"
                :key="index"
                :value="index"
                @click="sortMenuSelect(index)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </v-row>
    </v-sheet>

    <!-- Card -->
    <v-row no-gutters class="mb-16">
      <v-col v-for="shop in shops" :key="shop" cols="12" md="4">
        <ShopCardWithAI
          :shopObjects="shop"
          @showThumbImage="showThumbImageEmitEvent"
        />
      </v-col>
    </v-row>

    <SearchFilterDialog
      v-if="openSearchFilterDialog"
      :openSearchFilterDialog="openSearchFilterDialog"
      :searchFilterObjects="searchFilterObjects"
      @result="responseFromSearchFilterDialog"
      :prefectureList="prefectureList"
    ></SearchFilterDialog>

    <v-bottom-navigation grow bg-color="white">
      <v-btn
        :ripple="false"
        color="blue"
        variant="text"
        prepend-icon="mdi-tune-variant"
        value="left"
        @click="openSearchFilterDialog = true"
      >
        {{ $t("searchresults.filter") }}
      </v-btn>
      <v-btn
        :ripple="false"
        value="right"
        variant="text"
        color="blue"
        prepend-icon="mdi-sort"
      >
        {{ $t("searchresults.sort") }}
        <v-menu activator="parent">
          <v-list>
            <v-list-item
              v-for="(item, index) in sortItems"
              :key="index"
              :value="index"
              @click="sortMenuSelect(index)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>
    </v-bottom-navigation>
  </v-sheet>

  <v-dialog
    v-model="showThumbImage"
    :max-width="isMobileDevice.value ? '90%' : '80%'"
  >
    <Carousel :starting-image="0" :images="shopPictures" />
  </v-dialog>

  <v-dialog v-model="loadingDialog" persistent width="300">
    <v-sheet height="190" class="text-center">
      <v-progress-circular
        :indeterminate="loadingDialog"
        :size="100"
        color="blue"
        class="mt-12"
      >
        {{ $t("homeview.loading") }}
      </v-progress-circular>
    </v-sheet>
  </v-dialog>

  <VSonner position="top-center" />
</template>

<script>
import Carousel from "@/uitls/Carousel.vue";
import SearchFilterDialog from "@/components/SearchFilterDialog.vue";
import ShopCardWithAI from "@/components/ShopCardWithAI.vue";
import Constants from "@/uitls/Constants";
import Uitls from "@/uitls/Uitls";
import LanguagePack from "../locales/LanguagePack";
import { VSonner, toast } from "vuetify-sonner";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  components: {
    Carousel,
    SearchFilterDialog,
    ShopCardWithAI,
    VSonner,
  },

  i18n: {
    sharedMessages: LanguagePack,
  },

  watch: {},

  data() {
    return {
      showThumbImage: false,
      shops: [],
      sortItems: [
        this.$i18n.tc("searchfilter.latest"),
        this.$i18n.tc("searchfilter.oldest"),
        // this.$i18n.tc("searchfilter.popular"),
        // this.$i18n.tc("searchfilter.lowest_price"),
        // this.$i18n.tc("searchfilter.highest_price"),
      ],

      searchFilterObjects: {
        searchKeyword: "",
        prefecture_v2: 0,
        priceRange: [0, 1000],
        selectedTag: [],
        selectedTime: null,
      },

      searchPrefectureChip: [],
      searchChip: [],
      tagChip: [],
      timeChip: [],
      priceChip: [],

      openSearchFilterDialog: false,
      shopPictures: [],
      per_page: 10,
      loadingDialog: false,
      totalResult: "",
      sort: "id",
      prefectureList: [],
    };
  },

  async mounted() {
    this.setDefaultFilterObjects();
    await this.getPrefectureList();

    if (!Uitls.isEmpty(this.$cookies.get("searchresult_per_page"))) {
      this.per_page = parseInt(this.$cookies.get("searchresult_per_page"));
    }
    if (!Uitls.isEmpty(this.$cookies.get("sortType"))) {
      this.sort = this.$cookies.get("sortType");
    }
    if (this.$route.query.query) {
      const filterObject = JSON.parse(this.$route.query.query);
      if (filterObject.searchKeyword) {
        this.searchFilterObjects.searchKeyword = filterObject.searchKeyword;
      }
      if (filterObject.prefecture_v2) {
        this.searchFilterObjects.prefecture_v2 = parseInt(
          filterObject.prefecture_v2
        );
      }
      if (filterObject.priceRange) {
        this.searchFilterObjects.priceRange = filterObject.priceRange;
      }
      if (filterObject.selectedTag) {
        this.searchFilterObjects.selectedTag = filterObject.selectedTag;
      }
      if (filterObject.selectedTime) {
        this.searchFilterObjects.selectedTime = filterObject.selectedTime;
      }
    }
    this.setWindowScroll();
    this.searchShopData();
  },

  methods: {
    setDefaultFilterObjects() {
      this.searchFilterObjects = {
        searchKeyword: "",
        prefecture_v2: 0,
        priceRange: [0, 1000],
        selectedTag: [],
        selectedTime: null,
      };
      this.per_page = 10;
      this.sort = "id";
    },

    setWindowScroll() {
      window.onscroll = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        let bottomOfWindow = scrollPosition >= document.body.offsetHeight - 10;

        if (
          this.shops &&
          this.shops.length != this.totalResult &&
          bottomOfWindow &&
          !this.loadingDialog
        ) {
          this.per_page += 10;
          this.$cookies.set("searchresult_per_page", this.per_page, 60 + 60);
          this.searchShopData(false);
        }
      };
    },

    async searchShopData(resetShops = true) {
      this.loadingDialog = true;

      if (resetShops) {
        this.per_page = 10;
        this.$cookies.remove("searchresult_per_page");
        scrollTo(0, 0);
      }
      await this.handleFilterChips();

      const tobeSubmit = {
        product_id: Constants.BEAUTY_360_PRODUCT_ID,
        page: 1,
        per_page: this.per_page,
        sort: this.sort,
        keyword: this.searchFilterObjects.searchKeyword,
        prefecture_v2: this.searchFilterObjects.prefecture_v2,
        price: this.applyPriceFilter()
          ? JSON.stringify(this.searchFilterObjects.priceRange)
          : null,
        time: this.searchFilterObjects.selectedTime,
        tag: this.handleTagForApi(),
      };

      this.$router.replace({
        query: {
          query: JSON.stringify(this.searchFilterObjects),
        },
      });

      await this.$store
        .dispatch("shopStore/searchShopKeyword", tobeSubmit)
        .then((response) => {
          this.shops = [];
          this.totalResult = 0;
          if (response) {
            if (response.total) {
              this.totalResult = response.total;
            }
            if (response.data) {
              response.data.forEach((shop, i) => {
                if (shop.status) {
                  var shop_img_small = Constants.NO_IMAGE;
                  if (
                    !Uitls.isEmpty(shop.pictures[0]) &&
                    !Uitls.isEmpty(shop.pictures[0].picture_url_small)
                  ) {
                    shop_img_small = shop.pictures[0].picture_url_small;
                  }
                  this.shops.push(Uitls.setShopDetails(shop, shop_img_small)); 
                }else{
                  //Have hidden shop and stop for endless loop
                  this.totalResult --;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingDialog = false;
        });
    },
    handleFilterChips() {
      const vm = this;

      vm.searchChip = [];
      vm.searchPrefectureChip = [];
      vm.tagChip = [];
      vm.priceChip = [];
      vm.timeChip = [];

      if (vm.searchFilterObjects) {
        if (
          vm.searchFilterObjects.searchKeyword &&
          vm.searchFilterObjects.searchKeyword != ""
        ) {
          vm.searchChip = [vm.searchFilterObjects.searchKeyword];
        }
        if (
          vm.searchFilterObjects.prefecture_v2 >= 0 &&
          vm.prefectureList.length > 0
        ) {
          const prefectureData = vm.prefectureList.find(
            (v) => v.id == vm.searchFilterObjects.prefecture_v2
          );
          if (prefectureData) {
            vm.searchPrefectureChip = [prefectureData];
          }
        }
        if (vm.applyPriceFilter()) {
          vm.priceChip = [
            `RM${vm.searchFilterObjects.priceRange[0]} ~ RM${vm.searchFilterObjects.priceRange[1]}`,
          ];
        }

        if (
          vm.searchFilterObjects.selectedTag &&
          vm.searchFilterObjects.selectedTag.length > 0
        ) {
          vm.tagChip = vm.searchFilterObjects.selectedTag;
        }
        if (vm.searchFilterObjects.selectedTime) {
          vm.timeChip = [vm.searchFilterObjects.selectedTime];
        }
      }
    },
    handleTagForApi() {
      let result = [];
      if (this.searchFilterObjects.selectedTag.length > 0) {
        result = this.searchFilterObjects.selectedTag;
      }
      return result.length > 0 ? result.join(",") : null;
    },
    applyPriceFilter() {
      const vm = this;
      let defaultValue = false;
      if (
        vm.searchFilterObjects.priceRange &&
        vm.searchFilterObjects.priceRange.length > 0 &&
        vm.searchFilterObjects.priceRange[0] &&
        vm.searchFilterObjects.priceRange[1] &&
        (vm.searchFilterObjects.priceRange[0] != 0 ||
          vm.searchFilterObjects.priceRange[1] != 1000)
      ) {
        defaultValue = true;
      }

      return defaultValue;
    },

    closeFilterChips(closeType, selectedIndex = null) {
      const vm = this;
      if (vm.searchFilterObjects[closeType] !== undefined) {
        switch (closeType) {
          case "searchKeyword":
            vm.searchFilterObjects.searchKeyword = "";
            break;
          case "prefecture_v2":
            vm.searchFilterObjects.prefecture_v2 = 0;
            break;
          case "priceRange":
            vm.searchFilterObjects.priceRange = [0, 1000];
            break;
          case "selectedTime":
            vm.searchFilterObjects.selectedTime = null;
            break;
          case "selectedTag":
            if (
              selectedIndex >= 0 &&
              vm.searchFilterObjects.selectedTag[selectedIndex]
            ) {
              vm.searchFilterObjects[closeType].splice(selectedIndex, 1);
            } else {
              vm.searchFilterObjects.selectedTag = [];
            }
            break;
        }
      }
      vm.searchShopData();
    },
    closeSortChip() {
      this.sortMenuSelect(1);
    },

    showThumbImageEmitEvent(value) {
      const result = this.shops.find(({ shop_id }) => shop_id === value);

      if (result) {
        this.showThumbImage = true;

        this.shopPictures = [];

        for (let i = 0; i < result.shop_img_list.length; i++) {
          this.shopPictures.push({
            id: i,
            big: result.shop_img_list[i].picture_url,
            thumb: result.shop_img_list[i].picture_url_small,
          });
        }
      }
    },
    sortMenuSelect(index) {
      if (index == 0) {
        this.sort = "-id";
      } else if (index == 1) {
        this.sort = "id";
      } else {
        toast(this.$i18n.tc("shop.comingSoon"), {
          cardProps: {
            color: "info",
            class: "my-toast",
          },
        });
        return;
      }
      cookies.set("sortType", this.sort, 60 + 60);
      this.searchShopData();
    },
    responseFromSearchFilterDialog(obj) {
      this.openSearchFilterDialog = false;
      if (obj && obj.newSearchValue == true) {
        this.searchFilterObjects = {
          searchKeyword: obj.searchKeyword,
          prefecture_v2: obj.prefecture_v2,
          priceRange: obj.priceRange,
          selectedTag: obj.selectedTag,
          selectedTime: obj.selectedTime,
        };
        this.searchShopData();
      }
    },

    async getPrefectureList() {
      const vm = this;
      vm.prefectureList = [
        {
          id: 0,
          name: "all_area",
          code: "all_area",
        },
      ];

      await vm.$store
        .dispatch("shopStore/getPrefectureByCountry", "malaysia")
        .then(async (response) => {
          if (response && response.data && response.data.length > 0) {
            response.data.forEach((v) => {
              vm.prefectureList.push({
                id: v.id,
                name: v.name,
                code: v.code,
              });
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.v-chip {
  overflow: initial;
  color: #2196f3;
}

.v-select__selections {
  overflow: scroll;
  flex-wrap: nowrap;
}
</style>